export const FirebaseConfig = {
	"projectId": "gotacsai",
	"appId": "1:518801265714:web:6429369877ccce9a57182b",
	"databaseURL": "https://gotacsai-default-rtdb.firebaseio.com",
	"storageBucket": "gotacsai.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyD-Eq5GNtCKBjIVZKHZsMMmt4U8-S5cE9M",
	"authDomain": "gotacsai.firebaseapp.com",
	"messagingSenderId": "518801265714",
	"measurementId": "G-W1R8ESDFMS"
};